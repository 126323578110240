		
<template>
	<div class="outer-wrapper">
		
		<Loader :loading="isLoading"/>
		
		<div class="success-or-fail" v-if="!isLoading">
			
			<div id="top-nav"></div>
			
			<div class="inner-wrapper">

				<div class="heading-section">
					<h1 class="heading3">Do you want to remove this item?</h1>
				</div>
				
				<button class="button remove" @click="removeItem()">Remove</button>
				<button class="button return" @click="back()">Back</button>
			</div>
			<div class="bottom inner-wrapper" v-if="this.trip.tripItems[this.trip_item_id].type == 'flight'">
				<div><strong>or</strong></div>
				<div class="new-flight">Select new item to replace it</div>
				<button class="button" @click="replaceFlight()">Search</button>
			</div>
		</div>
	</div>
</template>

<script>
import router from '@/router';
import { mapState, mapActions, mapGetters } from 'vuex';
import Loader from '@/components/Loader.vue';

export default {
	data() {
		return {
			trip_id: this.$route.params.trip_id ? this.$route.params.trip_id : null,
			trip_item_id: this.$route.params.trip_item_id ? this.$route.params.trip_item_id : null,

			isLoading: false,
		}
	},
	computed: {
		...mapState({
			trip: state => state.trip,
		}),
	},
	methods: {
		...mapActions({
			removeTripItem: "trip/removeTripItem",
			prefillSearch: "flightsSearch/prefillSearchValues",
			alertSuccess: "alert/success",
			alertError: "alert/error"
		}),
		replaceFlight() {
			// Prefill the flightsSearch state using the existing flight data
			this.prefillSearch({ tripItemID: this.trip_item_id });

			// Navigate to the search process with query param for the trip item to replace
			router.push({
				name: 'Flights Search',
				query: {
					trip_id: this.trip_id,
					trip_item_id: this.trip_item_id,
				}
			})
		},
		back() {
			if(this.trip.tripItems[this.trip_item_id].type == 'flight') {
				router.push({
					name: 'Saved Flights Details',
					params: {
						trip_id: this.trip_id,
						trip_item_id: this.trip_item_id,
					}
				})
			} else {
				router.push({
					name: 'Saved Tour Details',
					params: {
						trip_id: this.trip_id,
						trip_item_id: this.trip_item_id,
					}
				})
			}
		},
		removeItem() {
			this.isLoading = true;
			this.removeTripItem({ tripID: this.trip_id, tripItemID: this.trip_item_id })
				.then(response => {
					this.alertSuccess('Item removed successfully');
					router.push({
						name: "Trip",
						params: {
							trip_id: this.trip_id,
						}
					})
					this.isLoading = false;
				})
				.catch(error => {
					this.$rollbar.error(error);
					this.isLoading = false;
					this.alertError(error);
				});
		}
	},
	components: {
		Loader,
	},
	created () {
		// Redirect if state is not properly loaded.
		if(!this.trip.tripID) {
			router.push({
				name: 'Trip',
				params: {
					trip_id: this.trip_id,
				}
			})
		}
	},
};
</script>	

<style scoped>
	.success-or-fail {
		text-align: center;
		min-height: calc(100vh - 85px);
		position: relative;
	}
	.success-or-fail .heading3 {
		padding-bottom: 42px;
	}
	.success-or-fail button {
		margin: 0 5px;
		margin-bottom: 15px;
	}
	.success-or-fail button.remove {
		background-color: #FA5252;
	}
	.success-or-fail button.return {
		background-color: white;
		border: 1px solid #118AB2;
		color: #118AB2;
		font-weight: 500;
	}
	.success-or-fail .bottom {
		box-sizing: border-box;
		text-align: center;
	}
	.success-or-fail .bottom .new-flight {
		margin-top: 10px;
		margin-bottom: 10px;
	}
</style>
		